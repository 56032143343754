/* eslint-disable */

<template>
  <a-select
    :mode="mode"
    v-model:value="valueParent"
    style="width: 300px"
    placeholder="Pilih Regional"
    allow-clear
    :show-arrow="true"
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @select="() => (findText = null)"
    show-search
    v-bind="$attrs"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'

export default {
  props: {
    mode: {
      type: String,
      default: 'multiple',
    },
    value: {
      default: () => [],
      type: Array,
    },
    level: {
      default: () => [],
      type: Array,
    },
    all: [Boolean],
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const findText = ref(null)
    const level = toRefs(props).level

    const fetchData = debounce((level, q) => {
      if (level === undefined || level.length <= 0) level = null

      apiClient
        .get('/api/wilayah/select/parent/' + level, {
          params: {
            q,
          },
        })
        .then(response => {
          data.value = response.data.result
        })
        .catch(err => {
          console.error(err)
        })
    }, 600)

    onMounted(() => {
      fetchData(level.value)
    })

    watch(level, (after, before) => {
      emit('update:value', [])
      fetchData(after)
    })

    const onSearch = debounce(value => {
      findText.value = value
      fetchData(level.value, value)
    }, 300)
    const deselect = value => {}

    const dropdownVisibleChange = value => {
      console.log({ dropdownVisibleChange: '', value })
      if (findText.value !== null) {
        fetchData(level.value)
        findText.value = null
      }
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return {
      data,
      valueParent: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      dropdownVisibleChange,
      deselect,
    }
  },
}
</script>
